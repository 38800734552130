import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { SelectOption } from '@rio-cloud/rio-uikit/types';
import SimpleDialog from '@rio-cloud/rio-uikit/lib/es/SimpleDialog';
import { VolkswagenAccountMenuProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocale } from '../../../../configuration/lang/actions';
import { getLocale } from '../../../../configuration';

export const VOLKSWAGEN_ACTION_BAR_ITEM_TEST_ID = 'VOLKSWAGEN_ACTIONBAR_ITEM_TEST_ID';

export const VolkswagenAccountMenu = (props: VolkswagenAccountMenuProps) => {
    const formattedPreferredUserName = props.preferredUsername ? ` (${props.preferredUsername.toUpperCase()})` : '';

    const [isShown, setShow] = useState(false);

    return (
        <ActionBarItem id={'accountMenu'}>
            <SimpleDialog
                title={<FormattedMessage id={'otc.vwusermenu.LanguageSelectionDialog'} />}
                content={<LanguageSelectionComponent />}
                show={isShown}
                onClose={() => setShow(false)}
            />
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-user'} data-testid={VOLKSWAGEN_ACTION_BAR_ITEM_TEST_ID} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover>
                <div className={'popover-content margin-0 padding-0'}>
                    <div data-cy={'user-details'} className={'padding-bottom-10 text-color-dark'}>
                        <div><b>{`${props.firstName} ${props.lastName}${formattedPreferredUserName}`}</b></div>
                        <div>{props.email}</div>
                    </div>
                    <hr className={'margin-top-5 margin-bottom-5'} />
                    <button type='button' className={'btn btn-info btn-link btn-link-inline'} onClick={() => setShow(true)} >
                        <span className={'rioglyph rioglyph-cog'} />
                        <FormattedMessage id={'otc.vwusermenu.button.languageSelection'} />
                    </button>
                    <hr className={'margin-top-5 margin-bottom-5'} />
                    <ul className={'list-unstyled'}>
                        <li>
                            <button className={'btn btn-link btn-s padding-0 text-color-dark'} tabIndex={1}
                                data-cy={'logout-btn'} onClick={props.logout}>
                                <span
                                    className={'rioglyph rioglyph-off margin-right-5 text-size-16 text-color-dark'} />
                                <FormattedMessage id={'otc.button.logout'} />
                            </button>
                        </li>
                    </ul>
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

const LanguageSelectionComponent = () => {
    const dispatch = useDispatch();
    const currentLocale = useSelector(getLocale);
    const options: SelectOption[] = [{ id: 'de-DE', label: 'Deutsch' }, { id: 'en-GB', label: 'English' }];
    return (
        <div>
            <Select
                options={options}
                onChange={(option: typeof options[0]) => dispatch(changeLocale(option.id))}
                value={[currentLocale]}
            />
        </div>
    );
};
